import logo from "./res/logo/camp-chair.png";
import laptop_png from "./res/read_laptop.png";
import unity_png from "./res/logo/unity_logo.png";
import unreal_png from "./res/logo/unreal_logo.png";
import cocos_png from "./res/logo/cocos_logo.png";
import "./App.css";

function App() {
  return (
    <div className="bonyfer-studio">
      <div className="firstContainer">
        <div className="header-class">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="about_us">Resume</div>
          <div className="about_us">Projects</div>
          <div className="about_us">Store</div>
          <div className="about_us">Contact</div>
          {/* <div className="header_email">sanjumahto3034@gmail.com</div> */}
        </div>

        <div className="title-content">
          <div className="title_cont1">
            <div className="title_name">I’m Unity </div>
            <div className="title_name">And Cocos</div>
            <div className="title_name">Game Developer</div>
            <div className="title_discription">
              <b>A frontend Game Developer in Assert Infotech. ~</b>
            </div>
            <a href="http://sanjumahto.epizy.com" className="title_store">
              My Store ~
            </a>
          </div>
          <div className="title_cont2">
            <img src={laptop_png} className="laptop_png" alt="logo" />
          </div>
        </div>

     
      </div>
      <div className="second-container">
        <div className="second-conterner-internal-div">
          <div className="second-conterner-internal-flex-div">

              <div className="about_engine-div">
               
                <div className="about-unity">
                    <div className="unity-profile-circle">
                      <img src={unity_png} className="unity-logo-profile" alt="logo" />
                    </div>
                      <div className="unity-engine-part">
                        <div className="unity-engine-title">Unity Engine</div>
                        <div className="unity-engine-discription"><b>2yrs+ Experience</b></div>
                      </div>
                </div>{/*  About Unity*/}

                <div className="about-cocos">
                    <div className="cocos-profile-circle">
                      <img src={cocos_png} className="cocos-logo-profile" alt="logo" />
                    </div>
                      <div className="cocos-engine-part">
                        <div className="cocos-engine-title">Cocos Engine</div>
                        <div className="cocos-engine-discription"><b>6month+ Experience</b></div>
                      </div>
                </div>{/*  About Cocos*/}

                <div className="about-unreal">
                    <div className="unreal-profile-circle">
                      <img src={unreal_png} className="unreal-logo-profile" alt="logo" />
                    </div>
                      <div className="unreal-engine-part">
                        <div className="unreal-engine-title">Unreal Engine 5</div>
                        <div className="unreal-engine-discription"><b>Still Learning It</b></div>
                      </div>
                </div>{/*  About unreal*/}


              </div>

              <div className="about_self-div">
                <div className="about-title-div"><b>What Can I Do ?</b></div>
                <div className="about-discription-div">
                    <div className="discription-about-1"><b>I have done many project on Unity in 2D and 3D that consit of Zombie Shooting, Casual Game, Infinity Runner Game, Mario Game, and Some Mission Based Game</b></div>
                    <div className="discription-about-1"><b>I have also exerience in Cocox2d and Cocos Creator I have create simple casual games in cocosx2d and Ludo in Cocos Creator</b></div>
                    <div className="discription-about-1"><b>Currenty I’m newbid in Unreal Engine I’m still Learning It.</b></div>
                </div>
                <div className="project-div">
                    <div className="project-complete-div">
                        <div className="project-complete-number"><b>20+</b></div>
                        <div className="project-complete-status"><b>Complete Game</b></div>
                    </div>


                    <div className="project-incomplete-div">
                        <div className="project-incomplete-number"><b>30+</b></div>
                        <div className="project-incomplete-status"><b>Project Game</b></div>
                    </div>
                </div>
              
              
              </div>


          </div>
        </div>
        </div>
    </div>
  );
}
<script></script>;

export default App;
